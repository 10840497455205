<template>
  <section id="banner">

    <!-- Slider -->
    <div id="main-slider" class="flexslider">
      <ul class="slides">
        <li>
          <img src="/frontend/img/slides/1.jpg" alt="magnifique bulding ofira "/>
          <div class="flex-caption">
            <p class="ofira-text">AGENCE IMMOBILIERE AGREE</p>
            <p style="color: #ffffff "> <strong>Osez le changement avec OFIRA SERVICES ENTREPRISE, Votre accompagnateur de rêve</strong></p>

          </div>
        </li>
        <li>
          <img src="/frontend/img/slides/3.jpg" alt="villa duplex ofira"/>
          <div class="flex-caption">
            <p class="ofira-text ">NOS VALEURS</p>
            <p style="color: #ffffff "><strong>l’humain est au cœur
              de nos priorités</strong></p>

          </div>
        </li>
      </ul>
    </div>
    <!-- end slider -->

  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Banner"
}
</script>

<style scoped>

</style>