<template>
  <footer>
    <div class="container">
      <form @submit.prevent="handleSubmit">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-10">
            <div class="form-group row">
              <div
                class="col-md-6 d-flex justify-content-center align-items-cente"
              >
                <label for="email" class=""><h4 class="texte-lg">Newsletter</h4></label>
              </div>
              <div
                class="col-md-6 d-flex justify-content-center align-items-cente"
              >
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Entrez votre email"
                  v-model="email"
                  disabled
                >
              </div>
            </div>
          </div>
          <div class="col-md-2 d-flex justify-content-center align-items-cente">
            <button class="btn btn-danger" type="submit">Envoyer</button>
          </div>
        </div>
      </form>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="widget">
            <h4 class="widgetheading">Nos Contacts</h4>
            <address>
              <strong>Abidjan</strong><br />
              Cocody, Riviera palmeraie,<br />
              Bd Saint Viateur.
            </address>
            <p>
              <i class="fa fa-phone"></i> (+225) 27 21 74 60 63 / 05 84 81 73 72
              <br />
              <i class="fa fa-envelope"></i> infos.secretariat@agence-ofira.com
            </p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="widget">
            <h4 class="widgetheading">Voir plus</h4>
            <ul class="link-list">
              <li><a href="#">Nos maisons</a></li>
              <li><a href="#">Règles et contrats</a></li>
              <li><a href="#">Nos services</a></li>
              <li><a href="#">Commencez</a></li>
              <li><a href="#">Évènementiels</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="widget">
            <h4 class="widgetheading">Publications récentes</h4>
            <ul class="link-list">
              <li><a href="#">....</a></li>
              <li><a href="#">....</a></li>
              <li><a href="#">....</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="widget">
            <h4 class="widgetheading">Nouveautés</h4>
            <ul class="link-list">
              <li><a href="#">....</a></li>
              <li><a href="#">....</a></li>
              <li><a href="#">....</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="sub-footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="copyright">
              <p>
                <span
                  >&copy; Ofira Service Entreprise Tous droits reservés.
                  Developpé Par </span
                ><a href="https://www.facebook.com/bt.numerique/" target="_blank">BT numérique </a>
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <ul class="social-network">
              <li>
                <a
                  href="https://www.facebook.com/Agence-Immobili%C3%A8re-OFIRA-107935237354578"
                  target="_blank"
                  data-placement="top"
                  title="Facebook"
                  ><i class="fa fa-facebook"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/agence-immobiliere-ofira-573103232/"
                  target="_blank"
                  data-placement="top"
                  title="Linkedin"
                  ><i class="fa fa-linkedin"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";
import { addDoc, collection } from "firebase/firestore";
import { projectFirestore } from "@/firebase/config";
import Swal from "sweetalert2";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  setup() {
    const successMessage = ref("");
    const email = ref("");
    const handleSubmit = async () => {
      const userMessage = {
        email: email.value,
      };

      // Check if the name is valid
      if (!email.value) {
        alert("Please enter your name.");
        return;
      }
      // Check if the email is valid
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.value)) {
        alert("Please enter a valid email address.");
        return;
      }

      try {
        const docRef = await addDoc(
          collection(projectFirestore, "newlestters"),
          userMessage
        );
        console.log("Document written with ID: ", docRef.id);
        email.value = "";
        successMessage.value = "Bienvenue dans la newletters de l'agence Ofira";
        await Swal.fire({
          title: "Félicitation!",
          text: successMessage.value,
          icon: "success",
          confirmButtonText: "Terminer",
        });
      } catch (err) {
        alert("An error occurred. Please try again later.");
      }
    };

    return {
      email,
      successMessage,
      handleSubmit,
    };
  },
};
</script>

<style scoped></style>
