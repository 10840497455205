<template>
  <div>
    <section id="inner-headline">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="pageTitle">A PROPOS DE L'AGENCE IMMOBILIÈRE AGREE OFIRA</h1>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <section class="section-padding">
        <div class="container">
          <div class="row showcase-section">
            <div class="col-md-6">
              <img src="/frontend/img/locale.jpg" alt="image du locale agence immobiliere ofira entreprise"
                class="img-fluid img-responsive">
            </div>
            <div class="col-md-6">
              <div class="about-text">
                <h2><strong>OFIRA SERVICES ENTREPRISE (O.S.E), L’IMMOBILIER PAR EXCELLENCE A ABIDJAN</strong></h2>
                <p>
                  Située à la Riviera Palmeraie Boulevard Saint Viateur, zone en pleine émergence, l'agence immobilière OFIRA SERVICES ENTREPRISE est une agence immobilière agréée, créée en Avril 2018, par Mme Juliette Akissi OURA qui en est la gérante. L’agence Immobilière OFIRA est spécialisée dans l’immobilier de moyen et haut standing : appartements, villas, résidences (meublés ou non, à moyen ou long terme) et locaux professionnels (bureaux, entrepôts et espaces commerciaux).
                </p>
                <p>
                  Riche de ses nombreuses années d’expériences, son authenticité, son honnêteté, sa rigueur et ses exigences lui ont permis d’acquérir sa notoriété sur le marché ivoirien. Elle s’est toujours donnée comme mission de faire évoluer ses équipes dans un environnement de travail sain et serein en leur transmettant son savoir-faire et en leur offrant les moyens matériels et humain leur permettant d’être en perpétuelle évolution.
                </p>
                <p>
                  La philosophie de l’agence est de placer l’humain au cœur de ses priorités ; S’agissant des propriétaires, des locataires et des acquéreurs. Une solution sur mesure est apportée à tous ses clients par un accompagnement personnalisé. L’agence et tous ses collaborateurs sont à votre écoute pour vous conseiller et vous accompagner sur l’ensemble de vos projets immobiliers.
                </p>
           
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="container">

        <div class="about">


          <div class="row">
            <div class="col-md-4">
              <!-- Heading and para -->
              <div class="block-heading-two">
                <h2><strong>Pourquoi nous choisir ?</strong></h2>
              </div>
              <p>En plus d'etre agréée par l'Etat ivoirien. L'Agence Immobilière OFIRA est l'un des leaders du marché de l'immobilier à Cocody et particulièrement dans la zone de la Riviera Palmeraie, notre agence immobilière est spécialisée dans la réalisation de transactions commerciales et de services en immobilier : Locations, Ventes, Acquisitions, Gestion de patrimoine, Conciergerie immobilière, Syndic de copropriété et Conseil en immobilier. Vous trouverez toujours en poussant la porte de notre agence, un accueil chaleureux, une équipe de professionnels et un suivi personnalisé.</p>
              <p>Nous offrons également des solutions de gestion locative et de transaction immobilière. Notre expertise couvre également des domaines tels que l’événementiel, la décoration, le règlement de conflits contractuels et le développement immobilier.</p>

            </div>
            <div class="col-md-4">
              <div class="block-heading-two">
                <h2><strong>Nos solutions</strong></h2>
              </div>
              <!-- Accordion starts -->
              <div class="panel-group" id="accordion-alt3">
                <!-- Panel. Use "panel-XXX" class for different colors. Replace "XXX" with color. -->
                <div class="panel">
                  <!-- Panel heading -->
                  <div class="panel-heading">
                    <h3 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-alt3" href="#collapseOne-alt3">
                        <i class="fa fa-angle-right"></i> GESTION LOCATIVE
                      </a>
                    </h3>
                  </div>
                  <div id="collapseOne-alt3" class="panel-collapse collapse">
                    <!-- Panel body -->
                    <div class="panel-body">
                      <ul>
                        <li>Publicité et mise en valeur du bien</li>
                        <li>Mise en location</li>
                        <li>Etude des dossiers locataire et rédaction de contrat</li>
                        <li>Etat des lieux d’entrée</li>
                        <li>Relance des locataires</li>
                        <li>Apurement de charges</li>
                        <li>Paiement des charges de copropriété et rapports avec le syndic</li>
                        <li>Rénovation et maintenance du bien</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="panel">
                  <div class="panel-heading">
                    <h3 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-alt3" href="#collapseTwo-alt3">
                        <i class="fa fa-angle-right"></i> TRANSACTION IMMOBILIÈRE

                      </a>
                    </h3>
                  </div>
                  <div id="collapseTwo-alt3" class="panel-collapse collapse">
                    <div class="panel-body">
                      <ul>
                        <li>Estimer le prix de son bien</li>
                        <li>Diffuser l’annonce</li>
                        <li>Signer la promesse de vente</li>
                        <li>Respecter le délai de rétractation de l’acheteur</li>
                        <li>Signer l’acte authentique</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Accordion ends -->

            </div>

            <div class="col-md-4">
              <div class="block-heading-two">
                <h2><strong>Notre Expertise</strong></h2>
              </div>
              <h3>Gestion locative</h3>
              <div class="progress pb-sm">
                <div class="progress-bar progress-bar-green" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                  aria-valuemax="100" style="width: 99%">
                  <span class="sr-only">40% Complete (success)</span>
                </div>
              </div>
              <h3>Règlement de conflits contractuels</h3>
              <div class="progress pb-sm">
                <div class="progress-bar progress-bar-lblue" role="progressbar" aria-valuenow="80" aria-valuemin="0"
                  aria-valuemax="100" style="width: 80%">
                  <span class="sr-only">40% Complete (success)</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>

</template>

<script>
 // import axios from 'axios';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "About",

  mounted () {
  window.scrollTo(0, 0)
    // axios.get('https://script.googleusercontent.com/a/macros/uvci.edu.ci/echo?user_content_key=jshcqclJqe7SWOyW5PYPZcKgpmewCDCCvqvkVsw82A4TW7eO4HmrDOrb94vhWSN9KLj47xhQqq90H0OJx5N0EaXoIfVKZstcm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_nRPgeZU6HP87WHjlqqGl7-YKZk343dFoLb4tQaJY6acA_Lto8gvK9ULOZWlbg5OxZW2X2j_UJf_tthCq0Uh7od_iMoYqJfYpluky9_-cuxKjMDLnpSR50_IeeKdcjmB8vJsw0wqpxiI&lib=MBpgr_0NKTvLGpcS9oBy_xdw1nH9IbQoU')
    //     .then(response => {
    //       this.people = response.data.data;
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
}
}
</script>

<style scoped>

</style>