<template>
  <div>
    <section id="inner-headline">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="pageTitle">
              CONTACTEZ L'AGENCE OFIRA VOTRE AGENCE IMMOBILIÈRE AGREE
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="about-logo">
              <h2>DÉCRIVEZ VOS BESOINS DANS LE FORMULAIRE CI DESSOUS</h2>
              <p>
                Pour toutes informations complémentaires, n’hésitez pas à utiliser le formulaire ci-dessous pour nous laisser un message. Nous vous répondrons dans les plus brefs délais.

              </p>
              <p>
                Notre service commercial est également à votre entière disposition du Lundi au vendredi de 08H00 à 17H30
                et les samedi de 9H00 à 12H35.
              </p>
            </div>
          </div>
        </div>
        <div class="row" id="service1">
          <div class="col-md-6">
            <!-- Form itself -->
            <form id="contact-form" @submit.prevent="handleSubmit" class="form">
              <h3>Contactez-nous</h3>
              <div class="form-group">
                <label for="name">Nom et Prénoms</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom et Prénoms"
                  id="name"
                  v-model="name"
                  disabled
                />
                <span class="error"></span>
              </div>

              <div class="form-group">
                <label for="email">Entrer votre adresse email</label>
                <input
                  type="email"
                  v-model="email"
                  class="form-control"
                  placeholder="Adresse Email"
                  id="email"
                  disabled
                />
              </div>

              <div class="form-group">
                <label for="message"
                  >Decrivez de facon precise votre probleme</label
                >
                <textarea
                  rows="10"
                  cols="100"
                  class="form-control"
                  placeholder="Message"
                  id="message"
                  v-model="message"
                  disabled
                  data-validation-required-message="Please enter your message"
                  minlength="5"
                  data-validation-minlength-message="Min 5 characters"
                  maxlength="999"
                >
                </textarea>
              </div>
              <!-- For success/fail messages -->
              <button type="submit" class="btn btn-primary pull-right disabled">
                ENVOYEZ
              </button>
              <br />
            </form>
          </div>
          <div class="col-md-6">

            <div class="thumbnail text-center">
              <img src="" alt="">
              <div class="caption">
                <h3><i class="fa fa-home"></i>
                </h3>
                <p><strong>Abidjan
                  Cocody, Riviera palmeraie,
                  Bd Saint Viateur.</strong></p>
                <p>
                 
                  <a href="https://www.google.com/maps/dir//agence+ofira+cote+d'ivoire/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xfc19355a51149db:0xf6b1ca19637dc6d1?sa=X&ved=2ahUKEwiSp-H_r5r9AhU-ZaQEHcnzDeUQ9Rd6BAhcEAU" target="_blank" class="btn btn-primary" role="button">Itineraire</a>
                
                </p>
              </div>
            </div>
            <div class="thumbnail text-center">
              <img src="" alt="">
              <div class="caption">
                <h3><i class="fa fa-phone"></i>
                </h3>
                <p> <strong>27 21 74 60 63 / 05 84 81 73 72</strong></p>
                <p>
          
                  <a href="tel:+2250584817372" target="_blank" class="btn btn-primary" role="button">Appelez</a>

                </p>
              </div>
            </div>
            <div class="thumbnail text-center">
              <img src="" alt="">
              <div class="caption">
                <h3><i class="fa fa-envelope-o"></i>
                </h3>
                <p><strong>infos.secretariat@agence-ofira.com</strong></p>
                <p>
                  <a href="mailto:infos.secretariat@agence-ofira.com" class="btn btn-primary" role="button">Écrire un mail</a>

                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="iframe-container">
            <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d993.053193570334!2d-3.9569471304274457!3d5.384503299662159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc19355a51149db%3A0xf6b1ca19637dc6d1!2sOFIRA%20Services!5e0!3m2!1sfr!2sci!4v1690473624564!5m2!1sfr!2sci"  style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import { projectFirestore } from "@/firebase/config.js";
import { collection, addDoc } from "firebase/firestore";
export default {
  name: `Home`,
  mounted() {
    window.scrollTo(0, 0)
  },
  setup() {
    const successMessage = ref("");
    const name = ref("");
    const email = ref("");
    const message = ref("");

    const handleSubmit = async () => {
      const userMessage = {
        name: name.value,
        email: email.value,
        message: message.value,
      };

      // Check if the name is valid
      if (!name.value) {
        alert("Please enter your name.");
        return;
      }

      // Check if the email is valid
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.value)) {
        alert("Please enter a valid email address.");
        return;
      }

      // Check if the message is valid
      if (!message.value) {
        alert("Please enter a message.");
        return;
      }

      try {
        const docRef = await addDoc(
          collection(projectFirestore, "users"),
          userMessage
        );
        console.log("Document written with ID: ", docRef.id);
        name.value = "";
        email.value = "";
        message.value = "";
        successMessage.value = "Merci pour votre message!";
        await Swal.fire({
          title: "Félicitation!",
          text: successMessage.value,
          icon: "success",
          confirmButtonText: "Terminer",
        });
      } catch (err) {
        alert("An error occurred. Please try again later.");
      }
    };

    return {
      name,
      email,
      message,
      successMessage,
      handleSubmit,
    };
  },
};
</script>

<style scoped>


.form-group {
  margin-top: 20px;
  font-size: 16px;
  color: #424041;
}
.form-group input {
  color: #424041;
  border: 1px solid rgba(136, 127, 15, 0.78);
}
.form-group textarea {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  border: 1px solid rgba(140, 132, 27, 0.78);
  margin-top: 5px;
}
.btn {
  background: #2F3238;
  text-align: center;
  color: #ffffff;
  padding: 12px 25px;
  border-radius: 0;
  border-color: transparent;
}
.btn:hover {
  background: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  color: #2F3238;
  padding: 12px 25px;
  border-radius: 0;
  border-color: transparent;
}

.fa:before {

  font-size: 2.5em;
  text-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.thumbnail {
  display: block;
  margin-top: 40px;
  border:none;
  box-shadow: none;
  line-height: 1.428571429;
  background-color: rgba(255, 255, 255, 0.78);
  animation: myAnim 2s cubic-bezier(0, 0, 1, 0.45) 0s 1 normal forwards;
  -webkit-transition: all .2s cubic-bezier(0, 0, 1, 0.45);
  transition: all .2s cubic-bezier(0, 0, 1, 0.45);
}
@keyframes myAnim {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.thumbnail:hover {
  display: block;

  margin-top: 40px;
  border:none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  line-height: 1.428571429;
  background-color: rgba(236, 223, 136, 0.78);
  animation: myAnim 2s ease 0s 1 normal forwards;
}

textarea {
  resize: vertical;
}
button {
  margin: auto;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  background: #424041;
  cursor: pointer;

}

/* Styles généraux pour le corps de la page (ou le conteneur principal) */
body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Hauteur de la fenêtre visible pour centrer le contenu */
}

/* Style pour le conteneur de l'iframe */
.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 25% /* Aspect ratio 16:9 (56.25 = 9 / 16 * 100) */
}

/* Style pour l'iframe elle-même */
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
