<template>
    <div class="m-2 p-2">
      <div class="container-fluid" id="topheader">
        <div class="row top-bar">
          <div class="col-md-12 m-2 p-0">
            <p class="pull-left hidden-xs ofira-text">BIENVENUE A L'AGENCE IMMOBILIERE AGREE OFIRA SERVICES ENTREPRISE (OSE)</p>
            <div class="pull-right ">
              <p class="pull-right"><i class="fa fa-phone"></i>(+001) 123-456-789</p><br>
              <p class="pull-right m-0 p-0"><i class="fa fa-envelope"></i>infos@ofira.org</p>
            </div>
          </div>
        </div>
      </div>
      <!-- start header -->
      <Header/>
      <router-view/>
      <Footer/>
      <!-- end header -->
  </div>
</template>

<script>
import Header from "@/views/pages/partials/Header";
import Footer from "@/views/pages/partials/Footer";
export default {
  name: `Master`,
  components :{Header,Footer},
}
</script>

<style scoped>
#topheader{
  background-color: #424041;
}
</style>