<!-- eslint-disable vue/valid-v-model -->
<template>
  <header>
    <div class="navbar navbar-default navbar-fixed-top">
      <div class="container-fluid">

        <div class="navbar-header page-scroll">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a href="/" class="pulse navbar-brand"> <img src="/frontend/img/logo.png"
              alt="logo de l'agence immobilière OFIRA Abidjan" class="logo-ofira img-responsive" /></a>
        </div>
        <div class="navbar-collapse collapse ">
          <ul class="nav navbar-nav">
            <li :to="{ name: 'home' }" :class="getActiveNavLink('home')">
              <a href="/">Accueil</a>
            </li>
            <li :to="{ name: 'services' }" :class="getActiveNavLink('services')">
              <router-link :to="{ name: 'services' }">Nos Services</router-link>
            </li>
            <li :to="{ name: 'about' }" :class="getActiveNavLink('about')">
              <router-link :to="{ name: 'about' }">A propos</router-link>
            </li>
            <li :to="{ name: 'contact' }" :class="getActiveNavLink('contact')">
              <router-link :to="{ name: 'contact' }">Contact</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import $ from 'jquery'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",

  methods: {

    getActiveNavLink(name) {
      //This is for the navbar classes, you can modify them as
      //as you need. (This will be assigned every-time we call this
      //function).
      let classString = "nav-item nav-link "

      //We compare the given name with the route current name.
      if (this.$route.name === name) {
        //If it is true, we append to the class string the "active" value
        classString += "active collapse";
        $(document).on('click.nav', '.navbar-collapse.in', function (e) {
          if ($(e.target).is('a')) {
            $(this).removeClass('in').addClass('collapse');
            console.log('ok')
          }

        },
        )

      }
      //Return the class string.
      return classString;
    },


  }
};
</script>

<style scoped>
.navbar {
  background: #222222;
}


.navbar-dark .navbar-nav .nav-item .active>::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #917f42;
  transition: 0.2s;
}

.navbar-dark .navbar-nav .nav-item .active> {
  width: 100%;
}

.navbar-dark .navbar-nav .active> {
  color: #fec400;
}



</style>