<template>
  <Banner />
  <section>
    <div class="purchase">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8">
            <p class="font">
              Spécialiste de l’immobilier de luxe et de l’investissement locatif , <strong>l’agence OFIRA vous accompagne dans
              toutes vos procédures immobilières.</strong>
              Avec connaissance parfaite de l'immobilier à Abidjan, <strong>l’agence OFIRA en unique interlocuteur </strong> vous libère de toute
              contrainte et assure la gestion de votre bien en toute quiétude.
            </p>
          </div>
          <aside class="col-md-4 ">
            <div class="pricing-action">
              <router-link class="btn btn-medium" :to="{ name: 'contact' }"><i class="icon-bolt"></i> Demande d'information</router-link>
            </div>
          </aside>
        </div>
      </div>
    </div>
    <hr>

  </section>
  <section>
    <div class="container">
      <div class="row">
        <div class="skill-home">
          <div class=" skill-home-solid clearfix">
            <div class="row">

              <div class="col-md-6 text-center" style="margin-bottom: 10px">
                <div class="wrimagecard wrimagecard-topimage">
                  <div class="wrimagecard-topimage_header" >
                    <span class="icons c1"><i class="fa fa-home"></i></span>
                  </div>
                  <div class="box-area text-left ">
                    <h3 class="text-white "><strong>Vendre une propriété</strong></h3>
                    <p>
                      Si vous souhaitez vendre votre bien immobilier, n’hésitez pas à
                      prendre contact avec nos agents experts.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6  text-center">
                <div class="wrimagecard wrimagecard-topimage">
                  <div class="wrimagecard-topimage_header"  >
                    <span class="icons c2"><i class="fa fa-group"></i></span>
                  </div>
                  <div class="box-area text-left">
                    <h3 class="text-white"> <strong>Agents experts</strong></h3>
                    <p>
                      En effet, nous disposons du
                      plus grand réseau de professionnels de l’immobilier à Abidjan,
                      ce qui nous permet de vous offrir une visibilité optimale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section >
    <div class="container-fluid ">
      <div>
        <h2 class="ofira-text text-uppercase">LOCATION D’APPARTEMENT À ABIDJAN</h2>
        <p class="text-dark" style="color: #1b1b1b">
          Nous vous proposons également une sélection d’appartements de prestige pour toutes
          vos locations, un ou plusieurs mois.
    
        </p><br>
        <div class="pricing-action" style="text-align: left">
          <router-link :to="{ name: 'contact' }" class="btn btn-medium"  style="max-width: 90%; overflow: hidden; text-overflow: ellipsis;"><i
              class="icon-bolt"></i> LOCATION D’APPARTEMENT </router-link >
        </div>
      </div>
      <div class="md-margin-bottom-30"></div>
      <div class="row service-v1 md-margin-bottom-40">
        <div class=" md-margin-bottom-40 col-md-3 col-sm-6 col-xs-12 gallery-item-wrapper apartments villas">
          <div class="gallery-item">
            <div class="gallery-thumb">
              <img alt="ofira realiste logo sur fond dorée" class="img-responsive" src="/frontend/img/b.jpg" width="400">
              <div class="image-overlay"></div>
              <a  href="/frontend/img/b.jpg" class="gallery-zoom"><i class="fa fa-eye"></i></a >
              <router-link :to="{ name: 'contact' }" class="gallery-link"><i class="fa fa-link"></i></router-link >
            </div>
          </div>
          <h3><router-link :to="{ name: 'contact' }" class="a">Nos appartements</router-link></h3>
          <p style="color: #2F3238">Découvrez notre sélection d’appartements Abidjanais d’exception pour toutes vos
            locations, courte ou longue durée.</p>
        </div>
        <div class=" md-margin-bottom-40 col-md-3 col-sm-6 col-xs-12 gallery-item-wrapper apartments villas">
          <div class="gallery-item">
            <div class="gallery-thumb">
              <img alt="Découvrez notre sélection d’appartements Abidjanais" class="img-responsive" src="/frontend/img/b.jpg" width="400">
              <div class="image-overlay"></div>
              <a href="/frontend/img/b.jpg" class="gallery-zoom"><i class="fa fa-eye"></i></a >
              <router-link :to="{ name: 'contact' }" class="gallery-link"><i class="fa fa-link"></i></router-link >
            </div>
          </div>
          <h3>  <router-link :to="{ name: 'contact' }" class="a">LOUER SON APPARTEMENT</router-link></h3>
          <p style="color: #2F3238">Si vous êtes propriétaire d’un bien immobilier et que vous souhaitez le mettre en location, confiez-le nous pour profiter
            de tous les avantages de la location de courte ou longue durée en toute sérénité.
            </p>
        </div>
        <div class="md-margin-bottom-40 col-md-3 col-sm-6 col-xs-12 gallery-item-wrapper apartments villas">
          <div class="gallery-item">
            <div class="gallery-thumb">
              <img alt="Si vous êtes propriétaire d’un bien immobilier" class="img-responsive" src="/frontend/img/b.jpg" width="400">
              <div class="image-overlay"></div>
              <a href="/frontend/img/b.jpg" class="gallery-zoom"><i class="fa fa-eye"></i></a>
               <router-link :to="{ name: 'contact' }" class="gallery-link"><i class="fa fa-link"></i></router-link>
            </div>
          </div>
          <h3> <router-link :to="{ name: 'contact' }" class="a">SÉJOUR PROFESSIONNEL</router-link></h3>
          <p style="color: #2F3238">Nous proposons également un service de location spécialement adapté pour les entreprises : des appartements
            parfaitement équipés.</p>
        </div>
        <div class="md-margin-bottom-40 col-md-3 col-sm-6 col-xs-12 gallery-item-wrapper">
          <div class="gallery-item">
            <div class="gallery-thumb">
              <img alt="Nous proposons également un service de location" class="img-responsive" src="/frontend/img/b.jpg" width="400">
              <div class="image-overlay"></div>
              <a href="/frontend/img/b.jpg" class="gallery-zoom"><i class="fa fa-eye"></i></a>
              <router-link :to="{ name: 'contact' }" class="gallery-link"> <i class="fa fa-link"></i></router-link>
            </div>
          </div>
          <h3> <router-link :to="{ name: 'services'}"  class="a">NOS SERVICES</router-link></h3>
          <p style="color: #2F3238">Nous proposons une sélection de services de qualité et personnalisés.</p>
          <a href=""></a>
        </div>
      </div>

    </div>
  </section>
  <section class="section-padding">
    <div class="container-fluid ">
      <div>
        <h2 class="ofira-text text-uppercase">IMMOBILIER DE PRESTIGE ET INVESTISSEMENTS À ABIDJAN</h2>
        <p class="text-dark" style="color: #1b1b1b">
          Vous êtes à la recherche d’un appartement, souhaitez vendre un bien ou réaliser un investissement locatif ? L’Agence Ofira vous conseille et vous propose les solutions les plus adaptées à vos besoins.
          Nous disposons d’une sélection de biens de prestige à la vente à Abidjan et à Bassam. Si vous êtes propriétaire d’un bien que vous souhaitez mettre en vente, nous pouvons vous mettre en relation avec des investisseurs sérieux et reconnus. Enfin, si vous cherchez à acheter ou louer un appartement, notre équipe vous accompagne dans votre recherche pour trouver rapidement le bien qui vous convient.
        </p><br>
        <div class="pricing-action" style="text-align: left">
          <router-link :to="{ name: 'contact' }" class="btn btn-medium"  style="max-width: 90%; overflow: hidden; text-overflow: ellipsis;"> <i
              class="icon-bolt"></i> IMMOBILIER DE PRESTIGE ET INVESTISSEMENTS À ABIDJAN</router-link>
        </div>
      </div>
      <div class="md-margin-bottom-30"></div>
      <div class="row service-v1 md-margin-bottom-30">
        <div class=" md-margin-bottom-30 col-md-4 col-sm-6 col-xs-12 gallery-item-wrapper apartments villas">
          <div class="gallery-item">
            <div class="gallery-thumb">
              <img alt="vendre un bien ou réaliser un investissement" class="img-responsive" src="/frontend/img/b.jpg">
              <div class="image-overlay"></div>
              <a href="/frontend/img/b.jpg" class="gallery-zoom"><i class="fa fa-eye"></i></a>
              <a href="#" class="gallery-link"><i class="fa fa-link"></i></a>
            </div>
          </div>
          <h3><router-link :to="{ name: 'contact' }" class="a">Biens à la vente</router-link></h3>
          <p style="color: #2F3238">Découvrez notre sélection de biens de prestige à la vente, à Abidjan.
          </p>
        </div>
        <div class=" md-margin-bottom-40 col-md-4 col-sm-6 col-xs-12 gallery-item-wrapper apartments villas">
          <div class="gallery-item">
            <div class="gallery-thumb">
              <img alt="Découvrez notre sélection de biens de prestige" class="img-responsive" src="/frontend/img/b.jpg">
              <div class="image-overlay"></div>
              <a href="/frontend/img/b.jpg" class="gallery-zoom"><i class="fa fa-eye"></i></a>
              <a href="#" class="gallery-link"><i class="fa fa-link"></i></a>
            </div>
          </div>
          <h3><router-link :to="{ name: 'contact' }" class="a">Confiez-nous votre bien</router-link></h3>
          <p style="color: #2F3238"> Vous êtes propriétaire d’un bien que vous souhaitez mettre en vente ? </p>
        </div>
        <div class="md-margin-bottom-40 col-md-4 col-sm-6 col-xs-12 gallery-item-wrapper apartments villas">
          <div class="gallery-item">
            <div class="gallery-thumb">
              <img alt="Ofira vous met en relation avec des investisseurs" class="img-responsive" src="/frontend/img/b.jpg">
              <div class="image-overlay"></div>
              <a href="/frontend/img/b.jpg" class="gallery-zoom"><i class="fa fa-eye"></i></a>
              <a href="#" class="gallery-link"><i class="fa fa-link"></i></a>
            </div>
          </div>
          <h3><router-link :to="{ name: 'contact' }" class="a">Recherche d'appartement</router-link></h3>
          <p style="color: #2F3238; margin-bottom: 5px">Que vous cherchiez à acheter ou louer un appartement, L’Agence
            Ofira vous accompagne dans votre recherche et vous permet de trouver en un temps record le bien qui vous
            convient.</p>
          <p style="color: #2F3238">Gagnez un temps précieux dans votre recherche d’appartement Abidjanais grâce aux
            chargés de recherche de L’Agence Ofira !</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>


import Banner from "@/views/pages/partials/Banner";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: { Banner }
}
</script>

<style >
.md-margin-bottom-30 {
  margin-bottom: 15px;

}

.wrimagecard {
  margin-top: 0;
  margin-bottom: 1.5rem;
  position: relative;
  background: #ffff;
  box-shadow: 12px 15px 20px 0 rgba(35, 50, 63, 0.15);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.wrimagecard .fa {
  position: relative;
  font-size: 70px;
}

.wrimagecard-topimage_header {
  padding: 20px;
}

a.wrimagecard:hover,
.wrimagecard-topimage:hover {
  box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
}

.wrimagecard-topimage a {
  width: 100%;
  height: 100%;
  display: block;
}

.wrimagecard-topimage a {
  border-bottom: none;
  text-decoration-line: none;
  color: #525c65;
  transition: color 0.3s ease;
}
</style>