<template>

  <section id="inner-headline">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="pageTitle">LES SERVICES DE L’AGENCE OFIRA </h1>
        </div>
      </div>
    </div>
  </section>
  <section id="content">
    <div class="container-fluid content">

      <div class="row">
        <div class="col-md-12">
          <div class="about-logo">
            <h2> <span class="ofira-text ">LES SERVICES DE L’AGENCE OFIRA</span></h2>
            <p>
              AGENCE OFIRA est une agence immobilière spécialisée dans l’immobilier de moyen et haut standing à Abidjan, offrant une large gamme de services, allant de la gestion locative à la décoration d’intérieur et à l’architecture. Nous sommes une entreprise authentique, honnête, rigoureuse et exigeante, ayant acquis sa notoriété grâce à ses nombreuses années d’expérience et son souci constant d’offrir des services de qualité à ses clients. Contactez-nous dès aujourd’hui pour
              en savoir plus sur nos services et comment nous pouvons vous aider dans vos projets immobiliers
            </p>

          </div>
        </div>
      </div>
      <div class="cards row">
        <div class="card card-1" v-for="service in services" :key="service.slug">
          <h3 class="card__icon">{{ service.titre }}</h3>
          <div class="card__title">
            <p>{{ service.description }}</p>
            <p>
              {{ service.detail }}

            </p>
            <ul>
              <li v-for="liste in service.nombres" :key="liste">{{ liste }}</li>

            </ul>
          </div>

          <p class="card__apply">

            <a class="card__link" :href="`${service.lien}`">CONTACTEZ-NOUS<i class="fas fa-arrow-right"></i></a>
          </p>
        </div>
      </div>
    </div>
  </section>

</template>

<script>


export default {

  name: `Services`,
  data: () => ({
    services: [
      {
        slug: 'gestion-locative',
        titre: 'Gestion Locative',
        description: `OFIRA est une agence immobilière, particulièrement reconnue pour la qualité de ses services de gestion locative offerts aux propriétaires. Nous mettons en œuvre notre sens de la négociation, notre capacité à sécuriser les actes et notre disponibilité pour répondre aux besoins de nos clients. `,
        detail: ` Nous offrons un service complet adapté pour gagner en temps et en tranquillité, notamment`,
        nombres: [
          'Publicité et mise en valeur du bien',
          'Mise en location',
          'Étude des dossiers locataires et rédaction de contrats ',
          'Relance des locataires',
          'Apurement de charges',
          'Paiement des charges de copropriété et rapports avec le syndic',
          'Rénovation et maintenance du bien'],
        lien: 'tel:+2252721746063',
      },
      {
        slug: 'achat-vente-location',
        titre: 'Achat / Vente / Location',
        description: `Nous sommes également spécialisés dans l’achat, la vente et la location de biens immobiliers à Abidjan. Notre rôle consiste non seulement à faire se rencontrer l’offre et la demande, mais aussi à faciliter la vente du bien immobilier.`,
        detail: `Nous vous éclairons sur les étapes importantes dans la vente d’un bien immobilier, à savoir :
        `,
        nombres: [
          'Estimer le prix de votre bien',
          "Diffuser l’annonce",
          'Signer la promesse de vente',
          "Respecter le délai de rétractation de l’acheteur",
          "Signer l’acte authentique",
        ],
        lien: 'tel:+2252721746063',
      },

      {
        slug: 'decoration-interieur-architecture',
        titre: `Décoration d'intérieur et Architecture`,
        description: `Passion, Créativité et Innovation sont les maîtres-mots de notre équipe de décorateurs et d’architectes. `,
        detail: `Nous créons un univers unique et inspirant qui vous ressemble en proposant des projets sur-mesure pour chaque espace. Nous sommes là pour vous aider à créer un lieu de vie qui vous ressemble grâce à :`,
        nombres: [
          'Étude de plan',
          'Conception 3D',
          'Projet Sur-Mesure',
          'Ensembles prêts à poser',
          'Des produits de qualités',
          'Sens du service',
          'Etc'

        ],
        lien: 'tel:+2252721746063',
      },
   
    ]

  }),
  mounted() {
    window.scrollTo(0, 0)
    this.services
  },


};
</script>

<style scoped>

#content > div > div.cards.row:before, #content > div > div.cards.row:after {content:none}
 #service1:before ,  #service1:after {content:none}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  margin: 15px;
  padding: 20px;
  display: grid;
  grid-template-rows: 20px auto 1fr 50px; /* Ajustement pour hauteur flexible */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  align-items: center; /* Centrer verticalement */
}

.card__link::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
  transform: translate(-50%, -50%);
}

.card:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

/* CARD INTERACTIONS */

.card__link,
.card__icon {
  position: relative;
  text-decoration: none;
  color: #424041;
}



.card__link:hover::after {
  width: 100%;
}

.card__icon {
  grid-row: 2/3;
  font-size: 20px;
}

.card__title {
  grid-row: 3/4;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: radial-gradient(#917f42, #f7f1a3);
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .cards {
    grid-template-columns: 1fr;
  }
}
</style>