import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/pages/Home.vue'
import About from '../views/pages/About.vue'
import Services from '../views/pages/Services.vue'
import Contact from '../views/pages/Contact.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Accueil Ofira | Votre agence immobilière agréée Abidjan',
      description: 'Pour toutes informations complémentaires, n’hésitez pas à nous contacter. Notre service commercial est également à votre entière disposition du Lundi au vendredi de 08H00 à 17H30 et les samedi de 9H à 12H35.',
      keywords: 'Ofira Services Entreprise, agence immobilière, gestion locative, Abidjan, Côte d\'Ivoire',
      robots: 'index, follow',
    },
  },
  {
    path: '/a-propos',
    name: 'about',
    component: About,
    meta: {
      title: 'A propos d\'Ofira | Votre agence immobilière agréée Abidjan',
      description: 'Pour toutes informations complémentaires, n’hésitez pas à nous contacter. Notre service commercial est également à votre entière disposition du Lundi au vendredi de 08H00 à 17H30 et les samedi de 9H à 12H35.',
      keywords: 'contact, agence immobilière, formulaire, message, commercial',
      robots: 'index, follow',
    },
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      title: 'Services Ofira | Votre agence immobilière agréée Abidjan',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'Contactez-nous | Votre agence immobilière agréée Abidjan',
      description: 'Pour toutes informations complémentaires, n’hésitez pas à nous contacter. Notre service commercial est également à votre entière disposition du Lundi au vendredi de 08H00 à 17H30 et les samedi de 9H à 12H35.',
      keywords: 'contact, agence immobilière, formulaire, message, commercial',
      robots: 'index, follow',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: Home,
    meta: {
      title: 'Page non trouvée',
      robots: 'noindex, nofollow',
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'OFIRA Services - Expert Immobilier à Abidjan'

  const description = document.querySelector('meta[name="description"]')
  if (description) {
    description.setAttribute('content', to.meta.description || '')
  }

  const keywords = document.querySelector('meta[name="keywords"]')
  if (keywords) {
    keywords.setAttribute('content', to.meta.keywords || '')
  }

  const robots = document.querySelector('meta[name="robots"]')
  if (robots) {
    robots.setAttribute('content', to.meta.robots || '')
    }
    next();
});


export default router
