import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJSdLO9T6I122u7pElghlbaGWRtjyS2c4",
  authDomain: "my-ofira-project.firebaseapp.com",
  projectId: "my-ofira-project",
  storageBucket: "my-ofira-project.appspot.com",
  messagingSenderId: "1013802786534",
  appId: "1:1013802786534:web:e51cc87d12c0b84a48e527",
  measurementId: "G-YCEBLPMYDH",
};

// init firebase app
const app = initializeApp(firebaseConfig);
getAnalytics(app);
// init firestore
// Initialize Cloud Firestore and get a reference to the service
const projectFirestore = getFirestore(app);

export { projectFirestore };
